import { ValidPatterModel } from '@models/profile-form-data.model';
import { AuthUserRoleEnum } from '@models/auth.model';

export class AccountFormModel {
  name: Pick<ValidPatterModel, 'validPattern' | 'min' | 'max'>;
  nameSuffix: Pick<ValidPatterModel, 'validPattern'>;
  middleName: Pick<ValidPatterModel, 'validPattern'>;
  password: Omit<ValidPatterModel, 'allowedTags' | 'allowedAttributes'>;
  cellarPassword: Omit<ValidPatterModel, 'allowedTags' | 'allowedAttributes'>;
  email: Pick<ValidPatterModel, 'validPattern'>;
  phoneNumber: Omit<
    ValidPatterModel<any[]>,
    'allowedTags' | 'allowedAttributes'
  >;
  secretPin: Pick<ValidPatterModel, 'validPattern'>;
  aaVersion: number;
  currencies: string[];
  securityQuestions: string[];
  contactReasons: string[];
  availableCellarRoles: string[];
  closeReasons: string[];
  deviceApp: Pick<ValidPatterModel, 'validPattern'>;
  deviceEmail: Pick<ValidPatterModel, 'validPattern'>;

  static fromJson(json: any): AccountFormModel {
    return {
      name: ValidPatterModel.fromJson(json.name),
      nameSuffix: ValidPatterModel.fromJson(json.name_suffix),
      middleName: ValidPatterModel.fromJson(json.middle_name),
      password: ValidPatterModel.fromJson(json.password),
      cellarPassword: ValidPatterModel.fromJson(json.cellar_password),
      email: ValidPatterModel.fromJson(json.email),
      phoneNumber: ValidPatterModel.fromJson(json.phone_number),
      secretPin: ValidPatterModel.fromJson(json.secret_pin),
      aaVersion: json.aa_version,
      currencies: json.currencies,
      securityQuestions: json.security_questions,
      contactReasons: json.contact_reasons,
      availableCellarRoles: json.available_cellar_roles,
      closeReasons: json.close_reasons,
      deviceApp: ValidPatterModel.fromJson({
        valid_pattern: json.device.valid_pattern.app
      }),
      deviceEmail: ValidPatterModel.fromJson({
        valid_pattern: json.device.valid_pattern.email
      })
    };
  }
}

export interface ICreateAccountReqBody {
  email: string;
  password: string;
  role: AuthUserRoleEnum;
}

export interface IAgreementReqBody {
  securityQuestion: string;
  securityAnswer: string;
  currency: string;
  aaVersion: any;
  firstName: string;
  middleName: string;
  lastName: string;
  contactName: string;
  nameSuffix: string;
  phoneNumber: any;
  secretPin: any;
}

export interface IUserVerifyReqBody {
  id: number;
  hash: string;
}

export interface IRecoverPasswordReqBody {
  email: string;
  hash: string;
  password: string;
  confirmPassword: string;
}
